import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from "../../assets/img/logo.png";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";
import { authenticate } from '../../action/Auth/Auth';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      errors: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit() {
    this.props.Authenticate(this.state.password);
  }

  render() {
    const {
      auth,
      busy,
      error
    } = this.props;

    const onSubmit = (event) => {
      event.preventDefault()
      this.handleSubmit()
    }

    return (
      <div className="flex flex-col items-center">
        {/* Main Card wrapper */}
        <div className="p-3 w-full sm:w-1/2 2xl:w-1/3">
          <div className="mt-4 mb-6 flex justify-center">
            <img src={Logo} alt="Logo" className="h-12" />
          </div>
          <MainCard type="main">
            <form onSubmit={onSubmit}>
              <div className="flex flex-col">
                <div className="text-2xl font-medium">Sign In</div>
                <div className="flex flex-col mb-6">
                  <label
                    htmlFor="fpassword"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password:
                  </label>
                  <input
                    type="password"
                    defaultValue=""
                    onChange={this.handleChange}
                    id="password"
                    className="h-12 mt-1 focus:ring-transparent focus:border-green-300 block w-full border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  {!busy && error && (
                    <div className="text-2xl font-medium">
                      {error}
                    </div>
                  )}
                  {busy ? (
                    <div className="bg-white opacity-50">
                      <Button size="md" type="primary">Signing In...</Button>
                    </div>
                  ) : (
                    <Button size="md" type="primary" onClick={this.handleSubmit}>Sign In</Button>
                  )}
                  {auth && <Redirect to={{ pathname: '/' }} />}
                </div>
                <div></div>
              </div>
            </form>
          </MainCard>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer.auth,
    busy: state.authReducer.busy,
    error: state.authReducer.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Authenticate: (password) => dispatch(authenticate(password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);