// Package Dependencies
import { combineReducers } from 'redux'
// Additional Dependencies
import AuthReducer from './Auth/Auth'
import CouncilReducer from './Councils/Council'
import StudentReducer from './Students/Student'
// Combine Reducers
const RootReducer = combineReducers({
  authReducer: AuthReducer,
  councilReducer: CouncilReducer,
  studentReducer: StudentReducer
})
// Export
export default RootReducer