import React from "react";

const SectionHeader = ({ label, subLabel }) => {
  return (
    <div>
      <div className="text-lg font-medium pb-1 border-b-1 border-gray-300">
        {label}
      </div>
      <div className="text-sm text-gray-500 mt-1">{subLabel}</div>
    </div>
  );
};

export default SectionHeader;
