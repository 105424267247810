// Package Dependencies
// Additional Dependencies
import {
  RETRIEVE_COUNCIL_REQUEST,
  RETRIEVE_COUNCIL_RESPONSE,
  RETRIEVE_COUNCIL_ERROR
} from '../../action/Councils/Council'

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  response: false,
  error: false,
  council: {
    name: '',
    location: ''
  }
}

// Council Reducer
function CouncilReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Retrieve Council Request
    case RETRIEVE_COUNCIL_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Council Response
    case RETRIEVE_COUNCIL_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        council: action.data
      }
    // Retrieve Council Error
    case RETRIEVE_COUNCIL_ERROR:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: 'Retrieve Council Error'
      }
    default:
      return state
  }
}

// Export
export default CouncilReducer