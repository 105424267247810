// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utilities/Axios'
// Action Dependencies
import {
  RETRIEVE_COUNCIL_REQUEST,
  retrieveCouncilResponse,
  retrieveCouncilError
} from '../../action/Councils/Council'

// Function Handle Council
function* handleCouncil(action) {
  try {
    // Retrieve Council Request
    const response = yield call(axios.get_request, `/councils/${action.id}`)
    // Retrieve Council Response
    yield put(retrieveCouncilResponse(response.data))
  } catch (error) {
    // Retrieve Council Error
    yield put(retrieveCouncilError(error))
  }
}

// Export
export const CouncilSaga = [
  takeLatest(RETRIEVE_COUNCIL_REQUEST, handleCouncil)
]