// Retrieve Council Request
export const RETRIEVE_COUNCIL_REQUEST = 'RETRIEVE_COUNCIL_REQUEST'
export function retrieveCouncilRequest(id) {
  return { type: RETRIEVE_COUNCIL_REQUEST, id }
}

// Retrieve Council Response
export const RETRIEVE_COUNCIL_RESPONSE = 'RETRIEVE_COUNCIL_RESPONSE'
export function retrieveCouncilResponse(data) {
  return { type: RETRIEVE_COUNCIL_RESPONSE, data }
}

// Retrieve Council Error
export const RETRIEVE_COUNCIL_ERROR = 'RETRIEVE_COUNCIL_ERROR'
export function retrieveCouncilError(data) {
  return { type: RETRIEVE_COUNCIL_ERROR, data }
}