import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux"
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";
import SectionHeader from "../../components/Form/SectionHeader";
import Logo from "../../assets/img/logo.png";
import { DateTime } from "luxon";
import Loading from "@alliancesafetycouncil/asc-loading";

// Additional Dependencies
import { retrieveStudentRequest } from "../../action/Students/Student"

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '8475672' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit() {
    this.props.FetchStudent(this.state.value);
  }

  render() {
    const {
      busy,
      error,
      student
    } = this.props;

    const onSubmit = (event) => {
      event.preventDefault()
      this.handleSubmit()
    }

    return (
      <div className="flex flex-col items-center">
        {/* Main Card wrapper */}
        <div className="p-3 w-full lg:w-2/3 2xl:w-1/2">
          <div className="mt-4 mb-6 flex justify-center">
            <img src={Logo} alt="Logo" className="h-12" />
          </div>
          <MainCard type="main">
            <form onSubmit={onSubmit}>
              <div className="grid grid-cols-1 gap-4">
                <div className="mb-2">
                  <SectionHeader label="Search for ARSC ID" />
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-col flex-grow">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Social Security Number (no dashes):
                    </label>
                    <input
                      type="password"
                      defaultValue=""
                      onChange={this.handleChange}
                      id="title"
                      className="h-12 mt-1 focus:ring-transparent focus:border-green-300 block w-full border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="ml-3 flex items-end">
                    <Button type="primary" size="md" onClick={() => this.handleSubmit() }>Search</Button>
                  </div>
                </div>
                {busy && !error &&
                  <Loading />
                }
                {!busy && error &&
                  <div className="bg-blue-50 p-6 rounded-lg flex items-center justify-center flex-col">
                    <i className="fal fa-info-circle text-3xl text-blue-500 mb-2"></i>
                    <div className="flex text-center">Hmmm... We could not find that student. Please try again.</div>
                  </div>
                }
                {!error && student.arsc_id &&
                  <div>
                    <div className="bg-blue-50 p-3 rounded-lg">
                      <div>
                        <SectionHeader label="ARSC ID" />
                      </div>
                      <div className="block text-2xl font-medium text-gray-900 mt-1">{student.arsc_id}</div>
                    </div>
                    <div className="bg-blue-50 p-3 rounded-lg">
                      <div>
                        <SectionHeader label="Councils that searched for this student" />
                      </div>
                        {student.councils.map((council, index) => {
                          return <div className="block my-6" key={`${index}-active`}>
                            <div className="text-gray-900 font-medium">
                              <Link to={`/councils/${council.id}/courses/${student.arsc_id}`}>{council.name}</Link>
                            </div>
                            <div className="text-sm text-gray-600">(Last searched on {DateTime.fromISO(council.last_search).toFormat('FFF')})</div>
                          </div>
                        })}
                    </div>
                  </div>
                }
              </div>
            </form>
          </MainCard>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.studentReducer.busy,
    error: state.studentReducer.error,
    student: state.studentReducer.student
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    FetchStudent: (ssn) => dispatch(retrieveStudentRequest(ssn))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);
