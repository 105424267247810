import {
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  SIGNOUT,
} from "../../action/Auth/Auth";

const INITIAL_STATE = {
  busy: false,
  error: false,
  auth: null
};

// Auth Reducer
function AuthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {
        busy: true,
        auth: false
      };
    case SIGNIN_SUCCESS:
      return {
        busy: false,
        auth: true
      };
    case SIGNIN_ERROR:
      return {
        busy: false,
        auth: false,
        error: action.error
      };
    case VERIFY_REQUEST:
      return {
        busy: true
      };
    case VERIFY_SUCCESS:
      return {
        busy: false,
        auth: true
      };
    case VERIFY_ERROR:
      return {
        busy: false,
        auth: false
      };
    case SIGNOUT:
      return {
        auth: false
      };
    default:
      return state;
  }
}

// Export
export default AuthReducer