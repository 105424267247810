// Package Dependencies
// Additional Dependencies
import {
  RETRIEVE_STUDENT_REQUEST,
  RETRIEVE_STUDENT_RESPONSE,
  RETRIEVE_STUDENT_ERROR
} from '../../action/Students/Student'

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  response: false,
  error: false,
  student: {
    arsc_id: '',
    councils: []
  }
}

// Students Reducer
function StudentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Retrieve Student Request
    case RETRIEVE_STUDENT_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Student Response
    case RETRIEVE_STUDENT_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        student: action.data
      }
    // Retrieve Student Error
    case RETRIEVE_STUDENT_ERROR:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: 'Retrieve Student Error'
      }
    default:
      return state
  }
}

// Export
export default StudentReducer