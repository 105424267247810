// Retrieve Students Request
export const RETRIEVE_STUDENT_REQUEST = 'RETRIEVE_STUDENT_REQUEST'
export function retrieveStudentRequest(studentIdentifier) {
  return { type: RETRIEVE_STUDENT_REQUEST, studentIdentifier }
}

// Retrieve Students Response
export const RETRIEVE_STUDENT_RESPONSE = 'RETRIEVE_STUDENT_RESPONSE'
export function retrieveStudentResponse(data) {
  return { type: RETRIEVE_STUDENT_RESPONSE, data }
}

// Retrieve Students Error
export const RETRIEVE_STUDENT_ERROR = 'RETRIEVE_STUDENT_ERROR'
export function retrieveStudentError(data) {
  return { type: RETRIEVE_STUDENT_ERROR, data }
}