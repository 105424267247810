// Package Dependencies
import Axios from "axios"
// Additional Dependencies
import Config from '../config/config'
// Intialize Axios Client
const client = Axios.create({ baseURL: Config.api.endpoint })
// Export
const axios = {
  // Set Authorization Token
  setAuthorizationToken: (token) => {
    client.defaults.headers.common["authorization"] = token
  },
  // Remove Authorization Token
  removeAuthorizationToken: () => {
    delete client.defaults.headers.common["authorization"]
  },
  // POST Request
  post_request: async (url, payload) => {
    try {
      const response = await client.post(url, payload)
      return response
    } catch (error) {
      throw error.response
    }
  },
  // GET Request
  get_request: async (url) => {
    try {
      const response = await client.get(url)
      return response
    } catch (error) {
      throw error.response
    }
  },
  // PUT Request
  put_request: async (url, payload) => {
    try {
      const response = await client.put(url, payload)
      return response
    } catch (error) {
      throw error.response
    }
  },
  // PATCH Request
  patch_request: async (url, payload) => {
    try {
      const response = await client.patch(url, payload)
      return response
    } catch (error) {
      throw error.response
    }
  },
  // DELETE Request
  delete_request: async (url) => {
    try {
      const response = await client.delete(url)
      return response
    } catch (error) {
      throw error.response
    }
  }
}

export default axios