// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import axios from '../../utilities/Axios'
// Action Dependencies
import {
  RETRIEVE_STUDENT_REQUEST,
  retrieveStudentResponse,
  retrieveStudentError
} from '../../action/Students/Student'

// Function Handle Student
function* handleStudent(action) {
  try {
    // Retrieve Students Request
    const response = yield call(axios.post_request, '/idsearch', { studentIdentifier: action.studentIdentifier })
    // Retrieve Students Response
    yield put(retrieveStudentResponse(response.data))
  } catch (error) {
    // Retrieve Students Error
    yield put(retrieveStudentError(error))
  }
}

// Export
export const StudentSaga = [
  takeLatest(RETRIEVE_STUDENT_REQUEST, handleStudent)
]