const session_name = 'arsc_auth'

export function getSession() {
  return sessionStorage.getItem(session_name);
}
export function setSession() {
  sessionStorage.setItem(session_name, true);
}

export function clearSession() {
  sessionStorage.removeItem(session_name);
}