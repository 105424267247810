// Package Dependencies
import { all } from 'redux-saga/effects'
import { CouncilSaga } from './Councils/Council'
import { StudentSaga } from './Students/Student'
// Export
export default function* RootSaga() {
  yield all([
    ...CouncilSaga,
    ...StudentSaga
  ])
}