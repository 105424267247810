import Bcrypt from 'bcryptjs';
import { clearSession, getSession, setSession } from '../../utilities/Session';
const hash = '$2a$12$Z8f3WadsCbys59YVCdBqve.PzrM8CSuN.0fcZuQRJ/NJvQJ9qnvgK'

export function authenticate(password) {
  return async (dispatch) => {
    dispatch(SignInRequest());

    if (password) {
      let comparisonResult = null
      try {
        comparisonResult = await Bcrypt.compare(password, hash);
      } catch (err) {
        console.log(err)
        comparisonResult = false
      }

      if (comparisonResult === true) {
        dispatch(SignInSuccess());
      } else {
        dispatch(SignInError('Incorrect Password. Please Try Again.'));
      }
    } else {
      dispatch(SignInError('Password Required'));
    }
  }
}

export function verify(session) {
  return async (dispatch) => {
    dispatch(VerifyRequest());

    const session = getSession();

    if (session) {
      dispatch(VerifySuccess());
    } else {
      dispatch(VerifyError());
    }
  };
}

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
function SignInRequest(data) {
  return { type: SIGNIN_REQUEST, data };
}

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
function SignInSuccess(data) {
  setSession();
  return { type: SIGNIN_SUCCESS, data };
}

export const SIGNIN_ERROR = 'SIGNIN_ERROR';
function SignInError(error) {
  return { type: SIGNIN_ERROR, error };
}

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export function VerifyRequest(token) {
  return { type: VERIFY_REQUEST };
}

export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export function VerifySuccess(data) {
  return { type: VERIFY_SUCCESS, data };
}

export const VERIFY_ERROR = 'VERIFY_ERROR';
export function VerifyError() {
  return { type: VERIFY_ERROR };
}

export const SIGNOUT = 'SIGNOUT';
export function SignOut() {
  clearSession();
  return { type: SIGNOUT };
}