import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import SignIn from './views/SignIn';
import Form from './views/Form';
import Courses from './views/Form/Courses';
import { verify } from './action/Auth/Auth';

const PrivateRoute = ({ auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth === true) {
          return rest.render(props);
        } else {
          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                state: { from: rest.location }
              }}
            />
          );
        }
      }}
    />
  );
};

class App extends Component {
  componentDidMount() {
    this.props.Verify();
  }

  render() {
    const { auth } = this.props;

    return (
      <div className="flex flex-row">
        <Router>
          <div className="w-full relative overflow-auto">
            <Switch>
              <Route exact path="/sign-in" name="SignIn" component={SignIn} />
              <PrivateRoute auth={auth} exact path="/councils/:councilId/courses/:arscId" name="Courses" render={(props) => <Courses {...props} />} />
              <PrivateRoute auth={auth} exact path="/" name="Form" render={() => <Form />} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Verify: () => dispatch(verify())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);