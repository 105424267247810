import React, { Component } from "react";
import { connect } from "react-redux"
import MainCard from "@alliancesafetycouncil/asc-card";
import SectionHeader from "../../components/Form/SectionHeader";
import Logo from "../../assets/img/logo.png";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../../assets/css/ag-grid-overrides.css";

// Additional Dependencies
import { retrieveCouncilRequest } from "../../action/Councils/Council"

const coursesList = [
  {code: "93875", course: "ARSC Confined Space Instructor-led", class_date: "04/12/2022", expiration: "04/12/2023"},
  {code: "49223", course: "ARSC Confined Space Refresher", class_date: "04/12/2022", expiration: "04/12/2023"},
  {code: "92821", course: "ARSC Confined Space Spanish", class_date: "04/12/2022", expiration: "04/12/2023"},
  {code: "59677", course: "ARSC Confined Spaces", class_date: "04/12/2022", expiration: "04/12/2023"}
]

class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultColDef: {
        sortable: true,
        filter: true,
        columns: false,
        quickFilterText: null
      },
      columnDefs: [
        { headerName: "Code",
          field: "code",
          width: 100
        },
        {
          headerName: "Course",
          field: "course",
          flex: 1
        },
        {
          headerName: "Class Date",
          field: "class_date",
          width: 130
        },
        {
          headerName: "Expiration",
          field: "expiration",
          width: 120
        },
      ],
      rowData: coursesList
    };
  }

  componentDidMount() {
    this.props.FetchCouncil(this.props.match.params.councilId)
  }

  render() {
    const {
      council,
      match
    } = this.props;

    return (
      <div className="flex flex-col items-center">
        {/* Main Card wrapper */}
        <div className="p-3 w-full lg:w-2/3 2xl:w-1/2 h-screen">
          <div className="mt-4 mb-6 flex justify-center">
            <img src={Logo} alt="Logo" className="h-12" />
          </div>
          <div className="h-full pb-24">
          <MainCard type="main">
            <div className="bg-blue-50 p-3 rounded-lg">
              <div>
                <SectionHeader label="ARSC ID" />
              </div>
              <div className="block text-2xl font-medium text-gray-900 mt-1">{match.params.arscId}</div>
            </div>
            <div className="mt-6">
              <SectionHeader label={`Course history from ${council.name}`} />
            </div>
            <div className="ag-theme-alpine w-full h-full">
             <AgGridReact
                 columnDefs={this.state.columnDefs}
                 rowData={this.state.rowData}
                 defaultColDef={this.state.defaultColDef}
                 quickFilterText={this.state.quickFilterText}
                 onGridReady={this.onGridReady}
                 >
             </AgGridReact>
           </div>
          </MainCard>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.councilReducer.busy,
    error: state.councilReducer.error,
    council: state.councilReducer.council
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    FetchCouncil: (id) => dispatch(retrieveCouncilRequest(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
